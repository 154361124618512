// import jwtDecode from "jwt-decode";
import axios, { AxiosResponse } from "axios";
import { notification } from "antd";
import { jwtDecode } from "jwt-decode";

//const API_URL = "https://backendtest.juicerobo.com"; // PROD
// Change the API URL to your backend URL
const API_URL = "https://backendtest.juicerobo.com"; // DEV
//  const API_URL = "http://localhost:8888"; // Local

// Function to create an instance of axios with necessary configurations
const createApiService = () => {
  const apiService = axios.create({
    baseURL: API_URL,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  let isRefreshing = false;
  let refreshPromise: Promise<AxiosResponse<unknown, unknown>> | null = null;

  // Helper function to handle token refresh failure
  const handleTokenRefreshFailure = () => {
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
    notification.error({
      message: "Session Expired",
      description: "Your session has expired. Please login again.",
    });
    window.location.href = "/login";
  };

  // Helper function to get user data from local storage or session storage
  const getUser = () => {
    const userData =
      localStorage.getItem("user") || sessionStorage.getItem("user");
    return userData ? JSON.parse(userData) : null;
  };

  // Helper function to refresh the access token
  // const refreshToken = async () => {
  //   const user = getUser();
  //   if (!user || !user.refreshToken) return null;
  //   try {
  //     const response = await apiService.post(
  //       "/admin/api/v1/usermanager/refreshToken",
  //       {
  //         refresh_token: user.refreshToken,
  //       }
  //     );
  //     if (response.status === 200) {
  //       const updatedUser = { ...user, ...response.data };
  //       if (localStorage.getItem("user")) {
  //         localStorage.setItem("user", JSON.stringify(updatedUser));
  //       } else {
  //         sessionStorage.setItem("user", JSON.stringify(updatedUser));
  //       }
  //       return response.data.accessToken;
  //     }
  //     throw new Error("Refresh token request failed");
  //   } catch (error) {
  //     console.log("Error refreshing access token:", error);
  //     return null;
  //   }
  // };

  // Interceptor to handle access token refresh and authentication headers
  apiService.interceptors.request.use(
    async (config: any) => {
      const userData =
        localStorage.getItem("user") || sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;

      if (user && user.accessToken) {
        const decodedToken: any = jwtDecode(user?.accessToken);
        const now = new Date().getTime() / 1000;

        // if (decodedToken?.exp - now < 60) {
        //   const newAccessToken = await refreshToken();

        //   if (newAccessToken) {
        //     user.accessToken = newAccessToken;
        //     if (localStorage.getItem("user")) {
        //       localStorage.setItem("user", JSON.stringify(user));
        //     } else {
        //       sessionStorage.setItem("user", JSON.stringify(user));
        //     }
        //   }
        // }

        const updatedConfig = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${user.accessToken}`,
            companyId: user.companyId,
          },
        };
        return updatedConfig;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Interceptor to handle response success and error
  apiService.interceptors.response.use(
    async (response) => response,
    async (error) => {
      const isSignInRequest = window.location.href.includes("/signin");
      if (
        error?.response?.data?.status === 401 ||
        (error?.response?.data?.status === 403 && !isSignInRequest)
      ) {
        const originalRequest = error.config;

        // if (!isRefreshing) {
        //   isRefreshing = true;
        //   refreshPromise = refreshToken()
        //     .then((newAccessToken) => {
        //       if (newAccessToken) {
        //         originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        //         return apiService(originalRequest);
        //       }
        //       handleTokenRefreshFailure();
        //       return Promise.reject(error);
        //     })
        //     .finally(() => {
        //       isRefreshing = false;
        //     });
        // }

        return refreshPromise;
      }

      // Handle other error responses, e.g., show a toast notification
      if (error?.response?.data?.status === 500) {
        notification.error({
          message: "Something went wrong!",
          description: "Please try again later.",
        });
      } else {
        notification.error({
          message: "Failed to fetch data.Something went wrong!",
          description: "Please try again later.",
        });
      }
      return Promise.reject(error);
    }
  );

  return apiService;
};

// Create the instance of apiService using the createApiService function
const apiService = createApiService();

// Helper functions to perform API calls
export const post = (url: string, data: unknown) =>
  apiService
    .post(url, data)
    .then((response) => response.data)
    .catch((error) => console.log(error));
// Promise.reject(error));

export const get = (url: string) =>
  apiService
    .get(url)
    .then((response) => response.data)
    .catch((error) => console.log(error));

export const put = (url: string, data: unknown) =>
  apiService
    .put(url, data)
    .then((response) => response.data)
    .catch((error) => console.log(error));

export const del = (url: string, data: unknown) =>
  apiService
    .delete(url, { data })
    .then((response) => response.data)
    .catch((error) => console.log(error));

export const deleteMethod = (url: string) =>
  apiService
    .delete(url)
    .then((response) => response.data)
    .catch((error) => console.log(error));
