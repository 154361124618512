import React, { useEffect, useState } from "react";
import { Button, Tag, Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { del, deleteMethod, get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { useNavigate } from "react-router-dom";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

function padZero(num) {
  return num < 10 ? "0" + num : num;
}

const Locations: React.FC = () => {
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/locations" },
      { label: "Locations", href: "/locations" },
    ]);
  }, [setBreadCrumb]);

  const handleMenuSync = async (Id) => {
    try {
      const payLoad = {
        locId: Id,
      };
      const response = await post(apiEndpoints.menuSync, payLoad);
      if (response.status === 200) {
        notification.success({
          message: "Menu Sync Success",
        });
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const handleMenuStatus = async (Id) => {
    try {
      const response = await get(`${apiEndpoints.menuSync}/${Id}`);
      if (response.status === 200) {
        notification.success({
          message: "Menu Sync Status Success",
          description: response?.data
            ?.map(
              (item) =>
                `Machine Address: ${item?.machineMenuID?.machineAddress}, Sync Status: ${item?.synced}`
            )
            .join("\n"),
          className: "notification-with-breaks",
        });
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const renderStatus = (value?: any, key?: any, record?: any) => {
    if (key === "userName") {
      if (record?.fcmDataID?.userName) {
        return (
          <Tooltip title={record.fcmDataID.userName}>
            <div>{record.fcmDataID.userName}</div>
          </Tooltip>
        );
      }
    }

    if (key === "isActive") {
      const color = record.active ? "green" : "volcano";
      return (
        <div>
          <Tag color={color}>{record.active ? "Active" : "Inactive"}</Tag>
        </div>
      );
    }

    if (key === "locationId") {
      if (value !== null) {
        return <CopyClipBoard text={value} />;
      } else {
        return "NA";
      }
    }
    if (key === "menuSync") {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <Button onClick={() => handleMenuSync(record.locId)}>Sync</Button>
          <Button onClick={() => handleMenuStatus(record.locId)}>Status</Button>
        </div>
      );
    }
    if (key === "locationAddress") {
      if (value !== null) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Tooltip title={value}>
              {/* <div>{value}</div> */}
              <div className="ellipsis-cell">{value}</div>
            </Tooltip>
            <MapIcon
              latitude={record?.latitude}
              longitude={record?.longitude}
            />
          </div>
        );
      } else {
        return "NA";
      }
    }
    if (key === "updatedAt") {
      if (value !== null && isISO8601Date(value)) {
        const date = new Date(value);
        // return date.toLocaleDateString();
        return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
          date.getDate()
        )}`;
      }
    }

    // Handling other truthy values with ellipsis and tooltip
    if (value) {
      return (
        <Tooltip title={value}>
          <div>{value}</div>
        </Tooltip>
      );
    }

    // Default case for falsy values other than boolean false
    return "NA";
  };

  const onSubmit = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const response = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const handleEdit = (record: any) => {
    navigate(`/locations/editLocation/${record.locId}`);
  };

  const handleDeleteConfirmation = async (id: string) => {
    try {
      const response = await deleteMethod(`${apiEndpoints.deleteLoc}/${id}`);
      if (response.status === 200) {
        notification.success({
          message: "Successfully Deleted",
        });
        await onSubmit();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = (record: any) => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete.",
      async () => {
        handleDeleteConfirmation(record?.locId);
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Loc Id",
      dataIndex: "locationId",
      key: "locationId",
      sorter: (a: any, b: any) =>
        (a?.locationId?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.locationId?.toString() ?? "").toLowerCase()),
      width: 100,
      // render: renderStatus,
      render: (value) => renderStatus(value, "locationId"),
    },
    {
      title: "Loc Name",
      dataIndex: "locationName",
      key: "locationName",
      sorter: (a: any, b: any) =>
        (a?.locationName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.locationName?.toString() ?? "").toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Loc Address",
      dataIndex: "locationAddress",
      key: "locationAddress",
      sorter: (a: any, b: any) =>
        (a?.locationAddress?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.locationAddress?.toString() ?? "").toLowerCase()),
      width: 100,
      render: (value, record) => renderStatus(value, "locationAddress", record),
    },
    {
      title: "Menu",
      dataIndex: "menuName",
      key: "menuName",
      sorter: (a: any, b: any) =>
        (a?.menuName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.menuName?.toString() ?? "").toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Menu Sync",
      dataIndex: "menuName",
      key: "menuName",
      sorter: (a: any, b: any) =>
        (a?.menuName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.menuName?.toString() ?? "").toLowerCase()),
      width: 100,
      render: (value, record) => renderStatus(value, "menuSync", record),
    },
    {
      title: "eCommerce Group",
      dataIndex: "outletName",
      key: "outletName",
      sorter: (a: any, b: any) =>
        (a?.outletName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.outletName?.toString() ?? "").toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "State",
      dataIndex: "stateName",
      key: "stateName",
      sorter: (a: any, b: any) =>
        (a?.stateName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.stateName?.toString() ?? "").toLowerCase()),
      width: 100,
      render: renderStatus,
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      width: 120,
      align: "center",
      render: (value, record) => renderStatus(value, "isActive", record),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 50,
      render: (record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />{" "}
          </Tooltip>{" "}
          &nbsp; &nbsp;&nbsp;
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <CommonTable
        routePath="/locations/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default Locations;
