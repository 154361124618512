import React, { useEffect, useState } from "react";
import {
  Form,
  DatePicker,
  Input,
  Button,
  message,
  Table,
  Select,
  Row,
  Modal,
} from "antd";
import DownloadButtons from "../../utils/DownloadFiles/page";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";
import "./page.scss";
import { useNavigate } from "react-router-dom";
import DataRefundTable from "../../../pages/Admin/Refund/transTable";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { post } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import { useRecoilState } from "recoil";
import { companyState } from "../../stores/companyId";
import { PlusOutlined } from "@ant-design/icons";

// Extend dayjs to understand custom formats
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const { Option } = Select;

interface CommonTableProps {
  routePath?: string;
  showDates?: boolean;
  showDropDowns?: boolean;
  showRefund?: boolean;
  pageName: string;
  dataSource: any[];
  columns: ColumnsType<any>;
  initialValues?: any;
  // machines?: any;
  locations?: any;
  onFinish?: any;
  onRefundSubmit?: any;
  fetchTransData?: any;
  transactionData?: any;
  footer?: any;
}

const CommonTable: React.FC<CommonTableProps> = ({
  routePath,
  showDates,
  showDropDowns,
  showRefund,
  pageName,
  dataSource,
  columns,
  initialValues,
  // machines,
  locations,
  onFinish,
  onRefundSubmit,
  fetchTransData,
  transactionData,
  footer,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactionNo, setTransactionNo] = useState("");
  const [amount, setAmount] = useState("");
  const [refundMessage, setRefundMessage] = useState("");
  const [machines, setMachines] = useState([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const [companyId] = useRecoilState(companyState);

  useEffect(() => {
    const storedDates = sessionStorage.getItem("selectedDates");
    if (storedDates) {
      const dates = JSON.parse(storedDates);
      // Ensure we parse the dates according to the specific format they were stored in
      const formattedDates = [
        dayjs(dates[0], "DD/MM/YYYY"),
        dayjs(dates[1], "DD/MM/YYYY"),
      ];
      form.setFieldsValue({ dates: formattedDates });
    }
  }, []);

  // Function to show the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle the closing of the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Function to update the input value state
  const handleInputChange = (e: any, key: string) => {
    if (key === "trans") {
      setTransactionNo(e.target.value);
    } else if (key === "message") {
      setRefundMessage(e.target.value);
    } else {
      setAmount(e.target.value);
    }
  };

  const handleFetchRefund = () => {
    if (transactionNo === "") message.error("Please enter Transaction Number");
    fetchTransData(transactionNo);
  };

  const handleSubmitRefund = () => {
    if (refundMessage === "") message.error("Please enter Refund Message");
    if (amount === "") message.error("Please enter Refund Amount");
    onRefundSubmit(transactionNo, refundMessage, amount);
  };
  // Format today's date in the desired format
  const today = dayjs().format("DD/MM/YYYY");

  // Set initial values for the form, including the date range
  const formInitialValues: any = {
    ...initialValues,
    dates: initialValues?.dates || [
      dayjs(today, "DD/MM/YYYY"),
      dayjs(today, "DD/MM/YYYY"),
    ], // Set initial dates here
  };

  // Call onFinish with initial values on component mount
  useEffect(() => {
    onFinish(formInitialValues);
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();

      const filtered = dataSource.filter((row) => {
        // Iterate over each key in the row
        return Object.keys(row).some((key) => {
          // Ensure the value is not undefined or null
          if (row[key] != null) {
            // Convert the value to a lowercased string for case-insensitive comparison
            const valueAsString = String(row[key]).toLowerCase();
            return valueAsString.includes(lowercasedSearchTerm);
          }
          return false;
        });
      });

      setFilteredData(filtered);
    } else {
      setFilteredData(dataSource);
    }
  }, [searchTerm, dataSource]);

  const onFinishFailed = (errorInfo: any) => {
    message.error("Please fill in all fields");
  };

  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== "undefined") {
      return window.innerWidth <= 768;
    }
    return false;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle date range change
  const onDatesChange = (dates, dateStrings) => {
    if (dates) {
      sessionStorage.setItem("selectedDates", JSON.stringify(dateStrings));
    }
  };

  const handleLocationChange = async (value) => {
    setMachines([]);
    form.setFieldsValue({
      Machine: "All",
    });
    const payLoad = {
      locationId: value,
      companyId: companyId || localStorage.getItem("companyId"),
    };
    const responseMachine = await post(
      apiEndpoints.getAllMachinesByLocationId,
      payLoad
    );
    if (
      responseMachine &&
      responseMachine.status === 200 &&
      responseMachine.data &&
      responseMachine.data.length > 0
    ) {
      setMachines([
        { machineName: "All", machineId: "All" },
        ...responseMachine.data,
      ]);
    }
  };

  // Function to determine the class based on the status value
  // const getRowClassName = (record) => {
  //   if (record.modified) {
  //     return `.ant-table-cell{
  //       background-color: #ba80f0 !important;
  //   }`;
  //   } else {
  //     return ""; // No class if no matching condition
  //   }

  //   // "green"; // Apply green background for approved status
  // };

  const getRowClassName = (record) => {
    return record.modified ? "modified-row" : "";
  };

  return (
    <>
      <div
        className="filters"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {showDates && (
          <Form
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "1.5rem",
            }}
            name="order_summary"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            initialValues={formInitialValues}
          >
            <Form.Item
              name="dates"
              label="Date Range"
              rules={[
                { required: true, message: "Please select a date range!" },
              ]}
            >
              <RangePicker
                format="DD/MM/YYYY"
                style={{ width: "15rem" }}
                onChange={onDatesChange}
              />
            </Form.Item>

            {showDropDowns && (
              <>
                <Form.Item
                  name="Location"
                  label="Location Name"
                  rules={[
                    { required: true, message: "Select a Location Name!" },
                  ]}
                >
                  <Select
                    onChange={handleLocationChange}
                    placeholder="Select Location Name"
                    style={{ width: "11rem" }}
                  >
                    {locations.map((location: any) => (
                      <Option
                        key={location?.locationId}
                        value={location?.locationId}
                      >
                        {location.locationName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="Machine"
                  label="Machine Name"
                  rules={[
                    { required: true, message: "Select a Machine Name!" },
                  ]}
                >
                  <Select
                    placeholder="Select Machine Name"
                    style={{ width: "11rem" }}
                  >
                    {machines.length > 0 &&
                      machines.map((machine: any) => (
                        <Option
                          key={machine.machineID}
                          value={machine.machineId}
                        >
                          {machine.machineName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "1.9rem" }}
              >
                Search
              </Button>
            </Form.Item>
          </Form>
        )}
        {showRefund && (
          <Button
            type="primary"
            style={{ marginTop: "1.9rem" }}
            onClick={showModal}
          >
            Refund
          </Button>
        )}
      </div>
      <Row
        style={{
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div>
          <label style={{ marginRight: "10px" }}>Quick Filter: </label>
          <Input
            style={{ width: "20rem" }}
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {!isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            {routePath !== "" && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                htmlType="submit"
                onClick={() => navigate(`${routePath}`)}
                style={{ marginRight: "10px" }}
              >
                Add
              </Button>
            )}
            <DownloadButtons tableData={filteredData} name={pageName} />
          </div>
        )}
      </Row>
      {isMobile && (
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            // flexDirection: "row",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate(`${routePath}`)}
            className="add"
          >
            Add
          </Button>
          <DownloadButtons tableData={filteredData} name={pageName} />
        </div>
      )}
      <div className="table-container">
        <Table
          columns={columns}
          dataSource={filteredData}
          rowClassName={getRowClassName} // Apply the rowClassName function
          // pagination={true}
          pagination={{
            // pageSize: 10, // Sets initial page size
            showSizeChanger: true, // Allows the user to change the page size
            pageSizeOptions: ["10", "20", "50", "100"], // Sets the available page size options
          }}
          scroll={{ x: 1300, y: 400 }}
          footer={footer}
        />
      </div>
      <Modal
        title="Refund Status"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <label>Transaction Number</label>
          <Input
            placeholder="Enter transaction number"
            onChange={(e) => handleInputChange(e, "trans")}
            value={transactionNo}
          />
          <Button
            type="primary"
            onClick={handleFetchRefund}
            style={{ marginTop: "10px" }}
          >
            Fetch
          </Button>
        </div>
        <div>
          <DataRefundTable data={transactionData} />
        </div>
        <div style={{ marginTop: "10px" }}>
          <div>
            <label>Message</label>
            <Input
              style={{ marginBottom: "10px" }}
              placeholder="Enter Message"
              onChange={(e) => handleInputChange(e, "message")}
              value={refundMessage}
            />
            <label>Refund Amount</label>
            <Input
              placeholder="Enter Refund Amount"
              onChange={(e) => handleInputChange(e, "amount")}
              value={amount}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              onClick={handleSubmitRefund}
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              Refund
            </Button>
            <Button
              // type="primary"
              onClick={handleCancel}
              style={{ marginTop: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CommonTable;
