import React, { useEffect, useState } from "react";
import {
  AimOutlined,
  DashboardOutlined,
  FileSyncOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Button,
  theme,
  Dropdown,
  Space,
  Avatar,
  Select,
  Image,
  Typography,
  Drawer,
} from "antd";
import DiscardChangeModal from "../components/modals/discard-change-modal";
import DynamicBreadcrumb from "../utils/BreadCrumb/page";
import { breadcrumbState } from "../stores/breadcrumb";
import { useRecoilState } from "recoil";
// import juiceman from "/juiceman.png";
// import org from "./org.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get } from "../../services/apiService";
import getApiEndpoints from "../../services/endpoints";
import { companyState } from "../stores/companyId";
import "./page.scss";

const { Header, Sider, Content } = Layout;
const { Option } = Select;

export interface AdminLayoutProps {
  children?: React.ReactNode;
}

const { Text } = Typography;
const { SubMenu } = Menu;

const menuData: any = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardOutlined />,
    roles: [
      "ROLE_ADMIN",
      "ROLE_OPERATOR",
      "ROLE_USER",
      "ROLE_OEM",
      "ROLE_STOCK",
      "ROLE_RENTALSPACE",
      "ROLE_FRANCHISE",
    ],
  },
  {
    title: "Reports",
    path: "/transaction",
    icon: <FileSyncOutlined />,
    roles: [
      "ROLE_ADMIN",
      "ROLE_RENTALSPACE",
      "ROLE_OPERATOR",
      "ROLE_USER",
      "ROLE_OEM",
      "ROLE_STOCK",
      "ROLE_FRANCHISE",
    ],
    children: [
      {
        title: "Order Summary",
        path: "/order-summary",
        roles: [
          "ROLE_ADMIN",
          "ROLE_RENTALSPACE",
          "ROLE_OPERATOR",
          "ROLE_USER",
          "ROLE_OEM",
          "ROLE_STOCK",
          "ROLE_FRANCHISE",
        ],
      },
      {
        title: "Order List",
        path: "/order-list",
        roles: [
          "ROLE_ADMIN",
          "ROLE_RENTALSPACE",
          "ROLE_OPERATOR",
          "ROLE_USER",
          "ROLE_OEM",
          "ROLE_STOCK",
          "ROLE_FRANCHISE",
        ],
      },
      {
        title: "Order Failure",
        path: "/order-failure",
        roles: [
          "ROLE_ADMIN",
          "ROLE_RENTALSPACE",
          "ROLE_OPERATOR",
          "ROLE_USER",
          "ROLE_OEM",
          "ROLE_STOCK",
          "ROLE_FRANCHISE",
        ],
      },
      {
        title: "Order Refunds",
        path: "/order-refund",
        roles: [
          "ROLE_ADMIN",
          "ROLE_RENTALSPACE",
          "ROLE_OPERATOR",
          "ROLE_USER",
          "ROLE_OEM",
          "ROLE_STOCK",
          "ROLE_FRANCHISE",
        ],
      },
      {
        title: "Payment Reports",
        path: "/payment-reports",
        roles: [
          "ROLE_ADMIN",
          "ROLE_OPERATOR",
          "ROLE_USER",
          "ROLE_OEM",
          "ROLE_STOCK",
        ],
      },
      {
        title: "Failure Summary",
        path: "/failure-summary",
        roles: ["ROLE_ADMIN"],
      },
    ],
  },
  {
    title: "Admin",
    path: "/admin",
    icon: <UserSwitchOutlined />,
    roles: ["ROLE_ADMIN"],
    children: [
      {
        title: "Admin Data",
        path: "/admin-data",
        roles: ["ROLE_ADMIN"],
      },
      {
        title: "Refund",
        path: "/refund",
        roles: ["ROLE_ADMIN"],
      },
      {
        title: "Mobile Devices",
        path: "/mobile-devices",
        roles: ["ROLE_ADMIN"],
      },
    ],
  },
  // {
  //   title: "Menu Master",
  //   path: "/menu",
  //   icon: <FileSyncOutlined />,
  //   roles: ["ROLE_ADMIN"],
  //   children: [

  //   ],
  // },
  {
    title: "Masters",
    path: "/Masters",
    icon: <AimOutlined />,
    roles: ["ROLE_ADMIN"],
    children: [
      {
        title: "Company",
        path: "/company",
        roles: ["ROLE_ADMIN"],
      },
      {
        title: "Locations",
        path: "/locations",
        roles: ["ROLE_ADMIN"],
      },
      {
        title: "Menu ",
        path: "/menu",
        roles: ["ROLE_ADMIN"],
      },
      {
        title: "Store Menu Price",
        path: "/store-menu-price",
        roles: ["ROLE_ADMIN"],
      },
      {
        title: "Machines",
        path: "/machines",
        roles: ["ROLE_ADMIN"],
      },
      {
        title: "eCommerce Group",
        path: "/outlet-group",
        roles: ["ROLE_ADMIN"],
      },
      {
        title: "Users",
        path: "/users",
        roles: ["ROLE_ADMIN"],
      },
      // {
      //   title: "User Roles Manage",
      //   path: "/user-roles-manage",
      //   roles: ["ROLE_ADMIN"],
      // },
      {
        title: "Items-Variants",
        // icon: <AimOutlined />,
        roles: ["ROLE_ADMIN"],
        children: [
          {
            title: "Items",
            path: "/item",
            roles: ["ROLE_ADMIN"],
          },
          {
            title: "Variants",
            path: "/variants",
            roles: ["ROLE_ADMIN"],
          },
        ],
      },
      // {
      //   title: "Current Events",
      //   path: "/current-events",
      //   roles: ["ROLE_ADMIN"],
      // },
      // {
      //   title: "Machine Map",
      //   path: "/machine-map",
      //   roles: ["ROLE_ADMIN"],
      // },
      // {
      //   title: "Price Plans",
      //   path: "/price-plans",
      //   roles: ["ROLE_ADMIN"],
      // },
      // {
      //   title: "Previous Events",
      //   path: "/previous-events",
      //   roles: ["ROLE_ADMIN"],
      // },
      // {
      //   title: "Company Types",
      //   path: "/company-types",
      //   roles: ["ROLE_ADMIN"],
      // },
      // {
      //   title: "SKU",
      //   path: "/sku",
      //   roles: ["ROLE_ADMIN"],
      // },
    ],
  },
];

const AdminLayout = (props: AdminLayoutProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [breadCrumb] = useRecoilState(breadcrumbState);
  const [companyId, setCompanyId] = useRecoilState(companyState);
  const [title, setTitle] = useState("");
  const [companyDropdown, setCompanyDropdown] = useState<any>([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const [filteredMenuData, setFilteredMenuData] = useState([]);

  const filterMenuDataByRole = (menuData, role) => {
    return menuData
      .filter((item) => item.roles.includes(role))
      .map((item) => {
        if (item.children) {
          // Recursively filter child items if they exist
          return {
            ...item,
            children: filterMenuDataByRole(item.children, role),
          };
        }
        return item;
      });
  };
  useEffect(() => {
    // const currentUserRole = getUserRole(); // Retrieve the current user's role
    const menu = filterMenuDataByRole(menuData, userRole);
    setFilteredMenuData(menu);
  }, []);

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const response = await get(apiEndpoints.getAllCompanies);
        if (response && response.status === 200 && response.data) {
          setCompanyDropdown(response.data);
          const storedCompanyId = localStorage.getItem("companyId");
          setCompanyId(storedCompanyId || response.data[0].companyMasterId);
        }
      } catch (error) {
        console.log("Error fetching companies:", error);
      }
    };

    fetchOrg();
  }, []);

  useEffect(() => {
    if (breadCrumb.length > 0) {
      const lastItem = breadCrumb[breadCrumb.length - 1];
      setTitle(lastItem.label);
    }
  }, [breadCrumb]);

  const renderMenuItems = (data: any) => {
    return data.map((item: any) => {
      if (item.children) {
        return (
          <SubMenu key={item.path} title={item.title} icon={item.icon}>
            {renderMenuItems(item.children)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item
          key={item.path}
          icon={item.icon}
          // style={{ paddingLeft: item.title === "Dashboard" ? "24px" : 0 }}
        >
          <Link to={item.path}>{item.title}</Link>
        </Menu.Item>
      );
    });
  };

  const handleLogoutClick = () => {
    DiscardChangeModal(
      "Click OK to Confirm to logout.",
      async () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      },
      () => {},
      { title: "Are you sure you want to logout ?" }
    );
  };

  const logoutItems = (
    <Menu>
      <Menu.Item className="logout-menu-item" onClick={handleLogoutClick}>
        Logout <LogoutOutlined />
      </Menu.Item>
    </Menu>
  );

  const onChange = (value: any) => {
    setCompanyId(value);
    localStorage.setItem("companyId", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== "undefined") {
      return window.innerWidth <= 768;
    }
    return false;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update this function to correctly identify the selected menu item based on pathname
  const findKeyByPath = (items: any, pathname: string) => {
    for (let item of items) {
      if (item.path === pathname) {
        return item.path; // Return the item's path as the key
      }
      if (item.children) {
        const childKey: any = findKeyByPath(item.children, pathname);
        if (childKey) return childKey;
      }
    }
  };

  // Calculate selectedKey based on current pathname
  const selectedKey = findKeyByPath(filteredMenuData, pathname);

  const userName = JSON.parse(
    sessionStorage.getItem("user") || localStorage.getItem("user")
  );
  const [first, setfirst] = useState("");
  // const [last, setlast] = useState("");
  useEffect(() => {
    if (userName) {
      setfirst(userName?.firstName);
      // setlast(userName?.lastName);
    }
  }, [userName]);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        {!isMobile && (
          <div className="sider">
            <Sider
              className="sider"
              style={{
                height: "100vh",
              }}
              // collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
            >
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  background: "black",
                  zIndex: 100,
                }}
              >
                <Image
                  preview={false}
                  loading="lazy"
                  src={collapsed ? "/juiceman.png" : "/Juice_logo.png"}
                  alt="logo"
                  width={collapsed ? 65 : 145}
                  height={collapsed ? 57 : 60}
                  style={{
                    margin: collapsed ? "0px 5px" : "5px 21px",
                    height: "55px",
                  }}
                />
              </div>
              <Menu mode="inline" theme="dark" selectedKeys={[selectedKey]}>
                {renderMenuItems(filteredMenuData)}
              </Menu>
            </Sider>
          </div>
        )}
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              position: "sticky",
              top: 0,
              zIndex: "100",
            }}
          >
            <div>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              {!isMobile && (
                <Text type="success" style={{ fontSize: "20px" }}>
                  {title}
                </Text>
              )}
              {!isMobile && (
                <Space
                  align="center"
                  size="small"
                  style={{
                    right: 0,
                    position: "absolute",
                    marginRight: "33px",
                  }}
                >
                  <Avatar src={"/org.png"} />
                  <Select
                    // style={{ width: "60%" }}
                    showSearch
                    placeholder="Select an Organization"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={filterOption}
                    value={
                      companyDropdown?.find(
                        (company: { companyId: any }) =>
                          company.companyId === companyId
                      )?.companyId
                    }
                  >
                    {companyDropdown &&
                      companyDropdown.map((company: any) => (
                        <Option
                          key={company.companyId}
                          value={company.companyId}
                        >
                          {company.companyName}
                        </Option>
                      ))}
                  </Select>

                  <label style={{ marginLeft: "10px", fontWeight: "600" }}>
                    {first}
                  </label>
                  <Dropdown overlay={logoutItems}>
                    <Avatar
                      icon={<UserOutlined />}
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                        marginRight: "3px",
                      }}
                    />
                  </Dropdown>
                </Space>
              )}
              {isMobile && (
                <>
                  <Select
                    style={{ width: "60%" }}
                    showSearch
                    placeholder="Select an Organization"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={filterOption}
                    value={
                      companyDropdown?.find(
                        (company: { companyId: any }) =>
                          company.companyId === companyId
                      )?.companyId
                    }
                  >
                    {companyDropdown &&
                      companyDropdown.map((company: any) => (
                        <Option
                          key={company.companyId}
                          value={company.companyId}
                        >
                          {company.companyName}
                        </Option>
                      ))}
                  </Select>
                  <Dropdown overlay={logoutItems}>
                    <Avatar
                      icon={<UserOutlined />}
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                        marginRight: "10px",
                        right: 0,
                        position: "fixed",
                        marginTop: "15px",
                      }}
                    />
                  </Dropdown>
                </>
              )}
            </div>
          </Header>
          {pathname !== "/dashboard" && (
            <DynamicBreadcrumb items={breadCrumb} />
          )}
          <Content
            style={{ padding: "0 16px", height: "100vh", overflowY: "auto" }}
          >
            <div
              style={{
                padding: 20,
                // height: "100%",
                background:
                  pathname === "/dashboard" ? "#f5f5f5" : colorBgContainer,
              }}
            >
              {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
      {isMobile && (
        <Drawer
          width={210}
          title={
            <img
              src="/Juice_logo.png"
              alt="logo"
              style={{ height: "22px", width: "120px", paddingTop: "11px" }}
            />
          }
          placement="left"
          onClose={() => setCollapsed(!collapsed)}
          open={collapsed}
          closable={false}
          extra={
            <Space>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
              />
            </Space>
          }
        >
          <Menu mode="inline" theme="dark">
            {renderMenuItems(filteredMenuData)}
          </Menu>
        </Drawer>
      )}
    </>
  );
};

export default AdminLayout;
