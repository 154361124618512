import { ReactNode, useMemo } from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  children: ReactNode;
  redirectPath?: string;
}

interface TokenPayload {
  exp?: number;
  [key: string]: any;
}

// Utility function to check if the token is expired
const isTokenExpired = (token?: string): boolean => {
  if (!token) return true;

  try {
    const payload = JSON.parse(atob(token.split(".")[1])) as TokenPayload;
    return !payload.exp || Date.now() >= payload.exp * 1000;
  } catch {
    return true;
  }
};

// The PrivateRoute component
const PrivateRoute = ({
  children,
  redirectPath = "/login",
}: PrivateRouteProps) => {
  const userData = useMemo(() => {
    const user = localStorage.getItem("user") || sessionStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  }, []);

  const isAuthenticated = useMemo(() => {
    const token = userData?.accessToken || userData?.refreshToken;
    return !isTokenExpired(token);
  }, [userData]);

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectPath} replace />
  );
};

export default PrivateRoute;
