import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  Button,
  message,
  Select,
  notification,
  Input,
  Switch,
  Row,
  Col,
  Spin,
} from "antd";
import { companyState } from "../../../lib/stores/companyId";
import "./page.scss";
import { MenuState } from "../../../lib/stores/menuId";

const MenuItemPriceForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menuId } = useParams();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { itemId } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  const [taxList, setTaxList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isStartLoading, setIsStartLoading] = useState(true);
  const [menuList, setMenuList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [variantList, setVariantList] = useState([]);

  const [selectMenu, setSelectMenu] = useState(null);
  const [selectItem, setSelectItem] = useState(null);
  const [selectVariant, setSelectVariant] = useState(null);
  const [menuItemsList, setMenuItemsList] = useState([]);
  // const [dynamicMenuId, setDynamicMenuId] = useRecoilState(MenuState);
  const [storeMenuList, setStoreMenuList] = useState([]);
  // const [storeMenuList, setStoreMenuList] = useState([]);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/menu" },
      { label: "Menu", href: `/menu-item-price/${menuId}` },
      {
        label: itemId ? "Edit Store Menu Price" : "Add Store Menu Price",
        href: itemId ? `/menu-item-price/${menuId}` : "/menu-item-price/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      // setIsStartLoading(true);
      const response = await get(`${apiEndpoints.menuitempricegud}/${itemId}`);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          menuId: response?.data?.menuId, // Ensure correct menuId is set here
          itemId: response?.data?.itemId,
          variantId: response?.data?.itemVariantId,
        };
        form.setFieldsValue(initialValues);
        setSelectMenu(response?.data?.menuId); // Correctly set selectMenu
        setSelectItem(response?.data?.itemId);
      }
    } catch (error) {
      console.log(error, "error");
      notification.error({
        message: error?.response?.data?.message || "Error",
      });
    } finally {
      // setIsStartLoading(false);
    }
  };

  // to get menu by menu id data
  const fetchMenus = async () => {
    try {
      const response = await get(`${apiEndpoints.menus}`);
      if (response.status === 200) {
        const menus = response?.data;
        setMenuList(menus); // Set the menu list for rendering
      }
    } catch (error) {
      console.error(error, "Error while fetching menus"); // Improved error logging
      message.error("Error while fetching menus");
    }
  };

  // to get Store by Store id data - List of Stores Dropdown (location_id) by company

  const fetchStore = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const response = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (response.status === 200) {
        setStoreList(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  // Get Store Menu by Store ID
  const fetchStoreMenuByStoreId = async (id) => {
    try {
      // const payLoad = {
      //   companyId: localStorage.getItem("companyId") || companyId,
      // };
      const response = await get(`${apiEndpoints.storeprice}/${id}`);
      if (response.status === 200) {
        setStoreMenuList(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const [storeMenuByMenuIdList, setStoreMenuByMenuIdList] = useState([]);

  //Get Store Menu by Menu ID
  const fetchStoreMenuByMenuId = async (id) => {
    try {
      // const payLoad = {
      //   companyId: localStorage.getItem("companyId") || companyId,
      // };
      const response = await get(`${apiEndpoints.menuprice}/${id}`);
      if (response.status === 200) {
        setStoreMenuByMenuIdList(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    fetchMenus();
    fetchStore();
  }, []);

  const onFinish = async (values: any) => {
    try {
      const payLoad = {
        ...values, // Copy other fields
      };

      setIsLoading(true);
      const endPoints = {
        updateDevice: "menuitempricegud",
        addDevice: "menuitempricegud",
      };

      const endPoint = itemId ? endPoints.updateDevice : endPoints.addDevice;
      const method = itemId ? put : post;

      const apiUrl = itemId
        ? `${apiEndpoints[endPoint]}/${itemId}`
        : apiEndpoints[endPoint];

      const response = await method(apiUrl, payLoad);
      if (response.status === 200) {
        notification.success({
          message: itemId ? "Updated Successfully" : "Created Successfully",
        });
        navigate("/menu-item-price");
      } else {
        notification.error({
          message: response?.message || "Error",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
      notification.error({
        message: error?.response?.data?.message || "Error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.error({
      message: "Please fill in all fields",
    });
  };

  const handleStore = async (Id) => {
    await fetchStoreMenuByStoreId(Id);
  };

  const handleMenuChange = (id) => {
    fetchStoreMenuByMenuId(id);
  };

  return (
    <>
      {/* {isStartLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Center vertically
            height: "50vh", // Full height of the viewport
          }}
        >
          <Spin />
        </div>
      ) : ( */}
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        initialValues={{}}
        className="item-form"
      >
        {/* Use Row and Col for layout */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="menuId"
              label="Select Menu"
              rules={[{ required: true, message: "Please select a menu!" }]}
            >
              <Select
                placeholder="Select Menu"
                value={selectMenu}
                onChange={handleMenuChange}
              >
                {menuList.map((menu) => (
                  <Select.Option key={menu?.menuId} value={menu?.menuId}>
                    {menu.menuName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="storeID"
              label="Select Store"
              rules={[{ required: true, message: "Please select an store!" }]}
            >
              <Select
                placeholder="Select Store"
                value={selectItem}
                onChange={handleStore}
              >
                {storeList.map((item) => (
                  <Select.Option
                    key={item?.locationId}
                    value={item?.locationId}
                  >
                    {item.locationName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="unitPrice"
              label="Price"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Enter price" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ marginRight: "10px" }}
          >
            {itemId ? "Update" : "Add"}
          </Button>
          <Button onClick={() => navigate("/menu-item-price")}>Cancel</Button>
        </Form.Item>
      </Form>
      {/* )} */}
    </>
  );
};

export default MenuItemPriceForm;
