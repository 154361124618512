import React, { useEffect, useState } from "react";
import { Button, Modal, Tag, Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import Table, { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { del, get } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { useNavigate } from "react-router-dom";
import { capitalizeWords } from "../../../lib/utils/common-formats";
import "./page.css";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const Company: React.FC = () => {
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState([]);

  // Function to open the modal
  const showModal = async (Id: any) => {
    try {
      const url = "/admin/api/v1/company/getListOfCompaniesUnderCompId";
      const response = await get(`${url}/${Id}`);
      if (response.status === 200) {
        setCompanyData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderStatus = (value?: any, key?: any, record?: any) => {
    if (key === "userName") {
      if (record?.fcmDataID?.userName) {
        return (
          <Tooltip title={record.fcmDataID.userName}>
            <div>{record.fcmDataID.userName}</div>
          </Tooltip>
        );
      }
    }
    if (key === "isActive") {
      const color = record.isActive === 1 ? "green" : "volcano";
      return (
        <div>
          <Tag color={color}>
            {record.isActive === 1 ? "Active" : "Inactive"}
          </Tag>
        </div>
      );
    }
    if (key === "createdAt") {
      if (value !== null && isISO8601Date(value)) {
        const date = new Date(value);
        // return date.toLocaleDateString();
        return date.toLocaleDateString();
      }
    }

    // Handling other truthy values with ellipsis and tooltip
    if (key === "companyName") {
      return (
        <Tooltip title={value}>
          <div
            onClick={() => showModal(record?.companyId)}
            className="menu-record"
          >
            {capitalizeWords(value)}
          </div>
        </Tooltip>
      );
    }
    if (value) {
      return (
        <Tooltip title={value}>
          <div>{value}</div>
        </Tooltip>
      );
    }

    // Default case for falsy values other than boolean false
    return "NA";
  };

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/company" },
      { label: "Company", href: "/company" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      const response = await get(apiEndpoints.getAllCompanies);
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const handleEdit = (record: any) => {
    navigate(`/company/editCompany/${record.companyId}`);
    console.log(record, "record");
  };

  const handleDeleteConfirmation = async (id: string) => {
    try {
      if (id) {
        const response = await del(`${apiEndpoints.deleteCompanyMaster}`, {
          companyId: id,
        });
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted",
          });
          await onSubmit();
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = (record: any) => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete.",
      async () => {
        handleDeleteConfirmation(record?.companyId);
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  const columnsCompany: ColumnsType<DataType> = [
    {
      title: "S.No",
      key: "serialNo",
      width: 80,
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 170,
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.companyName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.companyName?.toString() ?? "").toLowerCase()),
      render: (value, record) => renderStatus(value, "companyName", record),
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 170,
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.companyName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.companyName?.toString() ?? "").toLowerCase()),
      render: (value, record) => renderStatus(value, "companyName", record),
    },
    {
      title: "Company Type",
      dataIndex: "companyType",
      key: "companyType",
      sorter: (a: any, b: any) =>
        (a?.companyType?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.companyType?.toString() ?? "").toLowerCase()),
      width: 130,
      align: "center",
      render: renderStatus,
    },
    {
      title: "Company Email",
      dataIndex: "companyEmail",
      key: "companyEmail",
      sorter: (a: any, b: any) =>
        (a?.companyEmail?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.companyEmail?.toString() ?? "").toLowerCase()),
      width: 120,
      align: "center",
      render: renderStatus,
    },
    {
      title: "Company Phone",
      dataIndex: "companyPhone",
      key: "companyPhone",
      sorter: (a: any, b: any) =>
        (a?.companyPhone?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.companyPhone?.toString() ?? "").toLowerCase()),
      width: 120,
      align: "center",
      render: renderStatus,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 120,
      align: "center",
      render: (value, record) => renderStatus(value, "isActive", record),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 50,
      align: "center",
      render: (record) => (
        <>
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />
          </Tooltip>
          &nbsp; &nbsp;&nbsp;
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record)} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <CommonTable
        routePath="/company/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
      <Modal
        title="Company List"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Table
          columns={columnsCompany}
          dataSource={companyData}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default Company;
