import React, { useEffect, useState } from "react";
import { Button, Tooltip, message } from "antd";
import { useRecoilState } from "recoil";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { post } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import { companyState } from "../../../lib/stores/companyId";
import CommonTable from "../../../lib/components/Table/table";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CopyOutlined } from "@ant-design/icons";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Activate the customParseFormat plugin to handle specific date formats
dayjs.extend(customParseFormat);
const today = dayjs().format("DD/MM/YYYY");

const loadInitialValues = () => {
  const storedDates = sessionStorage.getItem("selectedDates");
  let formattedDates = [null, null];

  if (storedDates) {
    const dates = JSON.parse(storedDates);
    // Explicitly parse each date with the expected format
    formattedDates = [
      dayjs(dates[0], "DD/MM/YYYY").isValid()
        ? dayjs(dates[0], "DD/MM/YYYY")
        : null,
      dayjs(dates[1], "DD/MM/YYYY").isValid()
        ? dayjs(dates[1], "DD/MM/YYYY")
        : null,
    ];
  } else {
    formattedDates = [dayjs(today, "DD/MM/YYYY"), dayjs(today, "DD/MM/YYYY")];
  }

  // const initialValues = {
  //   // Machine: "all",
  //   // Location: "all",
  //   dates: formattedDates,
  // };
  const initialValues = {
    Machine: "All",
    Location: "All",
    dates: formattedDates,
  };

  return initialValues;
};

const OrderRefunds: React.FC = () => {
  const initialValues = loadInitialValues();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [machineDropdown, setMachines] = useState<any>([]);
  const [locationDropdown, setLocations] = useState<any>([]);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);

  // Function to check if a string is an ISO 8601 date
  const isISO8601Date = (value: any) => {
    const date = new Date(value);
    return !isNaN(date.getUTCDate());
  };

  function padZero(num) {
    return num < 10 ? "0" + num : num;
  }

  const formatNumber = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(number);

    return formattedNumber;
  };

  const renderStatus = (value?: any, key?: any, record?: any) => {
    if (key === "bool") {
      return value === true ? "Success" : "Failed";
    }
    if (key === "refundTotal") {
      const val = value / 100;
      const refundTotal = formatNumber(val / 1.12); // Adjust decimal places as needed
      return refundTotal ? `${refundTotal}` : "NA";
    }
    if (key === "revenue") {
      const revenue = formatNumber(
        (record.refundTotal / 100 / 1.12).toFixed(2)
      ); // Adjust decimal places as needed
      return `${revenue}`;
    }
    if (key === "gst") {
      const refundTotal = (record.refundTotal / 100 / 1.12).toFixed(2);
      const finalGST = Number(record.refundTotal / 100) - Number(refundTotal);
      const GST = formatNumber(finalGST.toFixed(2)); // Adjust decimal places as needed
      return `${GST}`;
    }

    if (key === "refundDate") {
      if (value !== null && isISO8601Date(value)) {
        const date = new Date(value);
        return date.toLocaleDateString();
      }
    }
    if (key === "date") {
      if (value !== null && isISO8601Date(value)) {
        const date = new Date(value);
        return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
          date.getDate()
        )} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(
          date.getSeconds()
        )}`;
      }
    }
    // Handling other truthy values with ellipsis and tooltip
    if (value) {
      return (
        <Tooltip title={value}>
          <div className="ellipsis-cell">{value}</div>
        </Tooltip>
      );
    }

    // Default case for falsy values other than boolean false
    return "NA";
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Refund Date",
      width: 120,
      dataIndex: "refundDate",
      key: "refundDate",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.refundDate?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.refundDate?.toString() ?? "").toLowerCase()),
      render: renderStatus,
    },
    {
      title: "Location",
      width: 220,
      dataIndex: "location",
      key: "location",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.location?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.location?.toString() ?? "").toLowerCase()),
      render: renderStatus,
    },
    {
      title: "Orignal Transaction Id",
      width: 230,
      dataIndex: "originalTransactionId",
      key: "originalTransactionId",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.originalTransactionId?.toString() ?? "")
          .toLowerCase()
          .localeCompare(
            (b?.originalTransactionId?.toString() ?? "").toLowerCase()
          ),
      render: renderStatus,
    },
    {
      title: "Refund Transaction Id",
      width: 220,
      dataIndex: "refundTransactionId",
      key: "refundTransactionId",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.refundTransactionId?.toString() ?? "")
          .toLowerCase()
          .localeCompare(
            (b?.refundTransactionId?.toString() ?? "").toLowerCase()
          ),
      render: renderStatus,
    },
    {
      title: "Glass Count",
      width: 220,
      dataIndex: "glassCount",
      key: "glassCount",
      align: "center",
      sorter: false,
      render: renderStatus,
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      align: "right",
      width: 100,
      sorter: false,
      render: (value) => formatNumber(Number(value).toFixed(2)),
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
      align: "right",
      width: 100,
      sorter: false,
      render: (value) => formatNumber(Number(value).toFixed(2)),
    },
    {
      title: "Refund Total",
      width: 100,
      dataIndex: "refundTotal",
      key: "refundTotal",
      align: "right",
      sorter: false,
      render: (value) => formatNumber(Number(value).toFixed(2)),
    },
    {
      title: "Refund Success",
      width: 150,
      dataIndex: "refundSuccess",
      key: "refundSuccess",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.refundSuccess?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.refundSuccess?.toString() ?? "").toLowerCase()),
      render: (value) => renderStatus(value, "bool"),
    },
    {
      title: "Refund Code",
      width: 200,
      dataIndex: "refundCode",
      key: "refundCode",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.refundCode?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.refundCode?.toString() ?? "").toLowerCase()),
      render: renderStatus,
    },
    {
      title: "Refund Message",
      width: 200,
      dataIndex: "refundMessage",
      key: "refundMessage",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.refundMessage?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.refundMessage?.toString() ?? "").toLowerCase()),
      render: renderStatus,
    },
    {
      title: "Refund  ReferenceId",
      width: 230,
      dataIndex: "refundReferenceId",
      key: "refundReferenceId",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.refundReferenceId?.toString() ?? "")
          .toLowerCase()
          .localeCompare(
            (b?.refundReferenceId?.toString() ?? "").toLowerCase()
          ),
      render: renderStatus,
    },
    {
      title: "Update Date",
      width: 150,
      dataIndex: "updateDateTime",
      key: "updateDateTime",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.updateDateTime?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.updateDateTime?.toString() ?? "").toLowerCase()),
      render: (value) => renderStatus(value, "date"),
    },
    {
      title: "Machine Name",
      width: 230,
      dataIndex: "machineName",
      key: "machineName",
      align: "center",
      sorter: (a: any, b: any) =>
        (a?.machineName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.machineName?.toString() ?? "").toLowerCase()),
      render: renderStatus,
    },
  ];

  useEffect(() => {
    setBreadCrumb([
      { label: "Transactions", href: "/order-refunds" },
      { label: "Order Refunds", href: "/order-refunds" },
    ]);
  }, [setBreadCrumb]);

  useEffect(() => {
    const payLoad = {
      companyId: localStorage.getItem("companyId") || companyId,
    };

    const fetchDropdowns = async () => {
      const responseMachine = await post(
        apiEndpoints.getAllMachinesByCompanyId,
        payLoad
      );
      if (
        responseMachine &&
        responseMachine?.status === 200 &&
        responseMachine?.data
      ) {
        setMachines([
          { machineName: "All", machineId: "all" },
          ...responseMachine.data,
        ]);
      }
      const responseLocation = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (
        responseLocation &&
        responseLocation?.status === 200 &&
        responseLocation?.data
      ) {
        setLocations([
          { locationName: "All", locationId: "All" },
          ...responseLocation.data,
        ]);
      }
    };
    fetchDropdowns();
  }, []);

  const onSubmit = async (values: any) => {
    try {
      const dates = values?.dates.map(
        (date: { format: (arg0: string) => any }) => date.format("DD/MM/YYYY")
      );
      const payLoad = {
        fromDate: dates[0],
        toDate: dates[1],
        companyId: localStorage.getItem("companyId") || companyId,
        machineId: values?.Machine === "All" ? null : values?.Machine,
        locationId: values?.Location === "All" ? null : values?.Location,
      };
      const response = await post(apiEndpoints.getRefundsByCompanyId, payLoad);
      if (response.status === 200) {
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const calculateTotals = (data: any) => {
    return data?.reduce(
      (acc, record) => {
        // Update totals for each column
        // acc.orderCount += record?.orderCount;
        acc.glassCount += record?.glassCount;
        acc.revenue += parseFloat(record?.revenue?.toFixed(2)); // Example for revenue, adjust accordingly
        acc.gst += parseFloat(record?.gst?.toFixed(2));
        acc.refundTotal += parseFloat(record?.refundTotal?.toFixed(2));
        // acc.gst += parseFloat(
        //   (Number(record?.total) - record?.total / 1.12).toFixed(2)
        // ); // Example for GST
        // acc.refundTotal += record?.refundTotal; // Assuming `amount` is the grand total
        // acc.aurobindoRevenue += parseFloat(
        //   ((record?.total / 1.12) * 0.1).toFixed(2)
        // ); // Example for Aurobindo Revenue
        return acc;
      },
      {
        // orderCount: 0,
        glassCount: 0,
        revenue: 0,
        gst: 0,
        refundTotal: 0,
        // aurobindoRevenue: 0,
      } // Initial totals
    );
  };

  const totals = calculateTotals(rowData);

  const normalFormatNumber = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-IN").format(number);

    return formattedNumber;
  };

  return (
    <>
      <CommonTable
        routePath={""}
        showDates={true}
        showDropDowns={true}
        pageName="Order-Refunds"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        // machines={machineDropdown}
        locations={locationDropdown}
        onFinish={onSubmit}
        footer={() => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <b>Total Order Count: {totals?.orderCount}</b> */}
            <b>Total Glasses Count: {normalFormatNumber(totals?.glassCount)}</b>
            <b>Total Revenue: {formatNumber(totals?.revenue.toFixed(2))}</b>
            <b>Total GST: {formatNumber(totals?.gst.toFixed(2))}</b>
            <b>Total Refund: {formatNumber(totals?.refundTotal.toFixed(2))}</b>
          </div>
        )}
      />
    </>
  );
};

export default OrderRefunds;
