import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Empty,
  Input,
  Modal,
  Pagination,
  Select,
  Tooltip,
  message,
  notification,
} from "antd";
import { useRecoilState } from "recoil";
import Table, { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import {
  del,
  deleteMethod,
  get,
  post,
  put,
} from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  MenuOutlined,
  RedoOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";
import { MenuState } from "../../../lib/stores/menuId";
import "./page.scss";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const renderStatus = (value?: any, key?: any, record?: any) => {
  // Handling other truthy values with ellipsis and tooltip
  if (key === "variantSize" && value) {
    return (
      <Tooltip title={value}>
        <div style={{ backgroundColor: record?.modified ? "#faaf69" : "" }}>
          {value} ml
        </div>
      </Tooltip>
    );
  } else if (value) {
    return (
      <Tooltip title={value}>
        <div style={{ backgroundColor: record?.modified ? "#faaf69" : "" }}>
          {value}
        </div>
      </Tooltip>
    );
  }

  // Default case for falsy values other than boolean false
  return "NA";
};

const MenuToStore: React.FC = () => {
  const { menuId } = useParams();
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };
  const [menuList, setMenuList] = useState([]);
  const [selectStore, setSelectStore] = useState(null);
  const [selectMenu, setSelectMenu] = useState(null);
  const [dynamicMenuId, setMenuId] = useRecoilState(MenuState);
  const [selectedMenuName, setSelectedMenuName] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState<any>();
  const [editableRow, setEditableRow] = useState(null); // To track the row being edited
  const [editUnitPrice, setEditUnitPrice] = useState({}); // For storing edited sortIds

  // useEffect(())
  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/store-menu-price" },
      { label: "Store Menu Price", href: `/store-menu-price` },
    ]);
  }, [setBreadCrumb]);

  const handleEdit = (record: any) => {
    navigate(`/store-menu-price/${record.storeId}/${record.menuId}`);
  };

  const handleDeleteConfirmation = async (
    storeId: string,
    menuId: string,
    variantId: string
  ) => {
    try {
      if (storeId && menuId) {
        const response = await deleteMethod(
          `${apiEndpoints.storemenuDelete}/${storeId}/${menuId}/${variantId}`
        );
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted",
          });
          await fetchStoreListByStoreId(selectStore, selectMenu);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = (record: any) => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete.",
      async () => {
        handleDeleteConfirmation(
          record.storeId,
          record?.menuId,
          record?.itemVariantId
        );
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  // Function to handle sortId change
  const handleeditUnitPriceChange = (value, record) => {
    setEditUnitPrice((prev) => ({
      ...prev,
      [record.unitPrice]: value, // Update sortId value for the specific row
    }));
  };

  // Function to handle Save button click
  const handleSave = (record) => {
    const updatedUnitPrice =
      editUnitPrice[record.unitPrice] || record.unitPrice;
    updateUnitPriceAPI(
      record.storeId,
      record.menuId,
      record.itemVariantId,
      updatedUnitPrice
    );
    setEditableRow(null); // Exit edit mode
  };

  const updateUnitPriceAPI = async (
    storeId,
    menuId,
    itemVariantId,
    updatedUnitPrice
  ) => {
    try {
      const payLoad = {
        menuId: menuId,
        storeId: storeId,
        itemVariantId: itemVariantId,
        unitPrice: Number(updatedUnitPrice),
      };

      const response = await post(`${apiEndpoints["storemenuprice"]}`, payLoad);
      // /${storeId}/${menuId}
      if (response.status === 200) {
        notification.success({
          message: "Updated Successfully",
        });
        await fetchStoreListByStoreId(selectStore, selectMenu);
      } else {
        notification.error({
          message: response?.message || "Error",
        });
      }
    } catch (error) {
      console.log(error, "error");
      notification.error({
        message: error?.response?.data?.message || "Error",
      });
    } finally {
      setEditUnitPrice({});
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Variant ID",
      dataIndex: "itemVariantId",
      key: "itemVariantId",
      sorter: (a: any, b: any) =>
        (a?.itemVariantId?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.itemVariantId?.toString() ?? "").toLowerCase()),
      width: 50,
      render: renderStatus,
    },
    {
      title: "Variant Name",
      dataIndex: "variantName",
      key: "variantName",
      sorter: (a: any, b: any) =>
        (a?.variantName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.variantName?.toString() ?? "").toLowerCase()),
      width: 50,
      render: renderStatus,
    },
    {
      title: "Item ID",
      dataIndex: "itemId",
      key: "itemId",
      sorter: (a: any, b: any) =>
        (a?.itemId?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.itemId?.toString() ?? "").toLowerCase()),
      width: 50,
      // render: renderStatus,
      render: (value) => renderStatus(value, "menu"),
    },
    {
      title: "Variant Size",
      dataIndex: "variantSize",
      key: "variantSize",
      sorter: (a: any, b: any) =>
        (a?.variantSize?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.variantSize?.toString() ?? "").toLowerCase()),
      width: 50,
      // render: renderStatus,
      render: (value) => renderStatus(value, "variantSize"),
    },
    {
      title: "Sort ID",
      dataIndex: "sortId",
      key: "sortId",
      sorter: (a: any, b: any) =>
        (a?.sortId?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.sortId?.toString() ?? "").toLowerCase()),
      width: 50,
      // render: renderStatus,
    },

    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      sorter: (a: any, b: any) =>
        (a?.unitPrice?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.unitPrice?.toString() ?? "").toLowerCase()),
      width: 50,
      // render: renderStatus,
      render: (value: any, record: any) =>
        editableRow === record.itemId ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Input
              value={
                editUnitPrice[record.unitPrice] !== undefined
                  ? editUnitPrice[record.unitPrice]
                  : value
              }
              onChange={(e) =>
                handleeditUnitPriceChange(e.target.value, record)
              }
              style={{ width: 80 }}
            />
            <Button
              icon={<SaveOutlined />}
              type="primary"
              onClick={() => handleSave(record)}
              style={{ marginLeft: 8 }}
            ></Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>{value}</div>
            <Button
              icon={<EditOutlined />}
              onClick={() => setEditableRow(record.itemId)}
              style={{ marginLeft: 8 }}
            ></Button>
          </div>
        ),
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 30,
      render: (record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tooltip title="Reset">
            <RedoOutlined onClick={() => handleDelete(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  const fetchMenus = async () => {
    try {
      const response = await get(apiEndpoints.menus);
      if (response.status === 200) {
        setMenuList(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  const fetchVariants = async () => {
    try {
      const response = await get(apiEndpoints.variants);
      if (response.status === 200) {
        setDataSource(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    fetchMenus();
    fetchVariants();
  }, []);

  const handleChange = async (value) => {
    // await fetchMenuItems(value);
    setSelectMenu(value);
    const menuName: any = menuList.filter((item) => item.menuId === value);
    setSelectedMenuName(menuName[0]?.menuName);
    // setMenuId(value);
    // localStorage.setItem("menuId", value);
  };

  const [storeList, setStoreList] = useState([]);

  const fetchStore = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const response = await post(
        apiEndpoints.getAllLocationsByCompany,
        payLoad
      );
      if (response.status === 200) {
        setStoreList(response?.data);

        const select =
          sessionStorage.getItem("store-storeId") ||
          response?.data[0]?.locationId;
        const selectM =
          sessionStorage.getItem("store-menuId") || response?.data[0]?.menuId;
        setSelectStore(select);
        setSelectMenu(selectM);
        if (select && selectM) {
          await fetchStoreListByStoreId(select, selectM);
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    fetchStore();
  }, []);

  const [menuItems, setMenuItems] = useState([]);

  // Get Store Menu by Store ID
  const fetchStoreListByStoreId = async (selectStore, selectMenu) => {
    try {
      const response = await get(
        `${apiEndpoints.storeprice}/${selectStore}/${selectMenu}`
      );
      if (response.status === 200) {
        setRowData(response?.data || []);
        setMenuItems(response?.data);
      } else {
        setRowData([]);
      }
    } catch (error) {
      console.log(error);
      // message.error("Error while fetching");
      setRowData([]);
    }
  };

  const [locationLink, setLocationLink] = useState("");

  const handleStore = async (Id) => {
    // await fetchStoreListByStoreId(Id);
    setSelectMenu(null);
    setSelectStore(Id);
    const filteredId = storeList?.filter((item) => item.locationId === Id);
    setSelectMenu(filteredId[0]?.menuId);
    setLocationLink(filteredId[0]?.locId);
    sessionStorage.setItem("store-storeId", Id);
    sessionStorage.setItem("store-menuId", filteredId[0]?.menuId);
    sessionStorage.setItem("store-locId", filteredId[0]?.menuId);
    // await fetchStoreListByStoreId(Id, filteredId);
  };

  useEffect(() => {
    // if (selectStore && selectMenu) {
    fetchStoreListByStoreId(selectStore, selectMenu);
    // }
  }, [selectStore, selectMenu]);

  const handleSortAndUpdate = async (dragIndex, dropIndex) => {
    const reorderedData = Array.from(menuItems);
    const [movedItem] = reorderedData.splice(dragIndex, 1);
    reorderedData.splice(dropIndex, 0, movedItem);
    const updatedData = reorderedData.map((item, index) => ({
      ...item,
      sortId: index + 1,
    }));

    setMenuItems(updatedData);
    try {
      const response = await post(apiEndpoints.menuitem, {
        menuId: Number(menuId),
        itemVariants: updatedData.map(({ itemVariantId, sortId }) => ({
          variantId: itemVariantId,
          sortId,
        })),
      });
      if (response?.status === 200) {
        notification.success({ message: "Sort order updated successfully" });
        fetchStoreListByStoreId(selectStore, selectMenu);
      }
    } catch (error) {
      console.error("Error posting data", error);
      notification.error({ message: "Error updating sort order." });
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    handleSortAndUpdate(source.index, destination.index);
  };

  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Sorting function
  const handleSort = (column: string) => {
    let newDirection: any = sortDirection === "asc" ? "desc" : "asc";
    if (sortColumn !== column) {
      newDirection = "asc"; // Default to ascending when sorting a new column
    }

    const sortedData = [...menuItems].sort((a, b) => {
      const aValue = a[column as keyof typeof a]; // Get the value by column
      const bValue = b[column as keyof typeof b]; // Get the value by column

      if (typeof aValue === "string" && typeof bValue === "string") {
        return newDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return newDirection === "asc" ? aValue - bValue : bValue - aValue;
      }

      return 0;
    });

    setMenuItems(sortedData);
    setSortColumn(column);
    setSortDirection(newDirection);
  };
  const renderSortIcon = (column: string) => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? "▲" : "▼";
    }
    return "▲▼"; // Show both arrows when not sorted
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Pagination logic to show data for the current page
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = menuItems.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  return (
    <div className="menu-store-page">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginBottom: "16px",
          // justifyContent: "space-between",
        }}
      >
        {/* <div style={{ display: "flex", gap: "10px" }}> */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Select Store</label>
          <Select
            style={{ width: "200px" }}
            placeholder="Select Store"
            value={selectStore}
            onChange={handleStore}
          >
            {storeList.map((item) => (
              <Select.Option key={item?.locationId} value={item?.locationId}>
                {item.locationName}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Menu</label>
          <Select
            disabled
            style={{ width: "200px" }}
            placeholder="Select Menu"
            value={selectMenu}
            // onChange={handleMenuChange}
          >
            {menuList.map((menu) => (
              <Select.Option key={menu?.menuId} value={menu?.menuId}>
                {menu.menuName}
              </Select.Option>
            ))}
          </Select>
        </div>
        {/* </div> */}
        {Number(selectMenu) === 0 && (
          <Button
            style={{ marginTop: "16px" }}
            title=" Menu is missing for this location please Assign for selected
            location by clicking here"
            type="primary"
            onClick={() => navigate(`/locations/editLocation/${locationLink}`)}
          >
            Map with Location
          </Button>
        )}
      </div>
      {/* <CommonTable
        routePath=""
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={fetchMenus}
      /> */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <div className="table-container">
              <table
                className="ant-table"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <thead className="ant-table-thead">
                  <tr>
                    <th></th>
                    {/* <th>Variant ID</th>
                    <th>Variant Name</th>
                    <th>Variant Size</th>
                    <th>Price</th>
                    <th>Sort ID</th>
                    <th>Updated At</th> */}
                    <th onClick={() => handleSort("itemVariantId")}>
                      Variant ID {renderSortIcon("itemVariantId")}
                    </th>
                    <th onClick={() => handleSort("variantName")}>
                      Variant Name {renderSortIcon("variantName")}
                    </th>
                    <th onClick={() => handleSort("variantSize")}>
                      Variant Size {renderSortIcon("variantSize")}
                    </th>
                    <th onClick={() => handleSort("itemId")}>
                      Item ID {renderSortIcon("itemId")}
                    </th>
                    <th onClick={() => handleSort("sortId")}>
                      Sort ID {renderSortIcon("sortId")}
                    </th>
                    <th onClick={() => handleSort("unitPrice")}>
                      Unit Price {renderSortIcon("unitPrice")}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {menuItems.map((item, index) => (
                    <Draggable
                      key={item.itemVariantId}
                      draggableId={item.itemVariantId.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="ant-table-row no-data"
                          style={{
                            backgroundColor: item?.modified ? "#faaf69" : "",
                          }}
                        >
                          <td>
                            <MenuOutlined style={{ cursor: "pointer" }} />
                          </td>

                          <td>{item.itemVariantId}</td>
                          <td>{item.variantName}</td>
                          <td>{item.variantSize} ml</td>
                          <td>{item.itemId}</td>
                          <td>{item.sortId}</td>
                          <td>
                            {/* {item.unitPrice} */}
                            {editableRow === item.itemVariantId ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Input
                                  value={
                                    editUnitPrice[item.unitPrice] !== undefined
                                      ? editUnitPrice[item.unitPrice]
                                      : item.unitPrice
                                  }
                                  onChange={(e) =>
                                    handleeditUnitPriceChange(
                                      e.target.value,
                                      item
                                    )
                                  }
                                  style={{ width: 80 }}
                                />
                                <Button
                                  icon={<SaveOutlined />}
                                  type="primary"
                                  onClick={() => handleSave(item)}
                                  style={{ marginLeft: 8 }}
                                ></Button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <div>{item.unitPrice}</div>
                                <Button
                                  icon={<EditOutlined />}
                                  onClick={() =>
                                    setEditableRow(item.itemVariantId)
                                  }
                                  style={{ marginLeft: 8 }}
                                ></Button>
                              </div>
                            )}
                          </td>
                          <td>
                            <Tooltip title="Reset">
                              <RedoOutlined
                                onClick={() => handleDelete(item)}
                              />
                            </Tooltip>{" "}
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </table>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* Pagination Component */}
      {menuItems?.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={menuItems?.length}
            onChange={handlePageChange}
            showSizeChanger
          />
        </div>
      ) : (
        <div
          style={{
            margin: "50%",
            width: "200px",
          }}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
};

export default MenuToStore;
