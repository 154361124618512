import { useEffect, useState } from "react";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../../services/apiService";
import getApiEndpoints from "../../../services/endpoints";
import {
  Form,
  // Input,
  Button,
  message,
  Select,
  // Checkbox,
  notification,
  Row,
  Col,
} from "antd";
import FormBuilder from "antd-form-builder";
import {
  emailValidator,
  latitudeValidator,
  longitudeValidator,
} from "../../../lib/utils/form-validator";
import { companyState } from "../../../lib/stores/companyId";
import "./page.css";

const MachineMapForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [companyId] = useRecoilState(companyState);
  // const [companyDropdown, setCompanyDropdown] = useState<any>([]);
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userDataNew: any = JSON.parse(user);
  const userRole: any = userDataNew?.roles[0];
  const apiEndpoints: any = getApiEndpoints(userRole);
  // const [companyTypes, setCompanyTypes] = useState([]);
  const [eventsDropdown, setEventsDropdown] = useState([]);
  const [machinesDropdown, setMachinesDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/dashboard" },
      { label: "Machine Map", href: "/machine-map" },
      {
        label: id ? "Edit Machine Map" : "Add Machine Map",
        href: id ? `/machine-map/${id}` : "/machine-map/add",
      },
    ]);
  }, [setBreadCrumb]);

  const fetchById = async () => {
    try {
      const payLoad = {
        companyId: id,
      };
      // replace endpoint with location id endpoint to get details
      const response = await post(apiEndpoints.getCompanyMasterId, payLoad);
      if (response.status === 200) {
        const initialValues = {
          ...response?.data,
          active: response?.data?.isActive === 1 ? true : false,
        };
        form.setFieldsValue(initialValues);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (location.pathname.includes("edit-machine-map")) {
      fetchById();
    }
  }, [location]);

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    try {
      const payLoad = {
        companyId: localStorage.getItem("companyId") || companyId,
        isActive: location.pathname.includes("current-events") || false,
      };
      const response = await post(
        apiEndpoints.getAllActiveOrOldEventsByCompanyId,
        payLoad
      );
      if (response.status === 200) {
        setEventsDropdown(response?.data);
      }
      const payLoadMachine = {
        companyId: localStorage.getItem("companyId") || companyId,
      };
      const companyDevices = await post(
        apiEndpoints.getMachineListForEvents,
        payLoadMachine
      );
      if (companyDevices.status === 200) {
        setMachinesDropdown(companyDevices?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onFinish = async (values: any) => {
    try {
      // const modifiedValues = {
      //   ...values,
      //   active: values.active ? 1 : 0,
      // };

      // Now use modifiedValues for submission
      const payLoad: any = { ...values, companyId: companyId };
      setIsLoading(true);
      const endPoints = {
        updateDevice: "updateLocationMaster",
        addDevice: "addLocationMaster",
      };
      const endPoint: any = id ? endPoints.updateDevice : endPoints.addDevice;
      const method = id ? put : post;
      const response: any = await method(apiEndpoints[`${endPoint}`], payLoad);
      if (response.status === 200) {
        notification.success({
          message: id ? "Updated Successfully" : "Created Successfully",
        });
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    message.error("Please fill in all fields");
  };

  const companyFormMeta: any = [
    {
      name: "eventId",
      label: "Event Name",
      type: "select",
      placeholder: "Select Event Name",
      rules: [{ required: true, message: "Select a Event Name!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: eventsDropdown.map((c: any) => ({
          value: c.eventId,
          label: c.eventName,
        })),
      },
    },
    {
      name: "machineId",
      label: "Machine Name",
      type: "select",
      placeholder: "Select Machine Name",
      rules: [{ required: true, message: "Select a Machine Name!" }],
      // options: [],
      widget: Select,
      widgetProps: {
        options: machinesDropdown.map((c: any) => ({
          value: c.machineId,
          label: c.machineName,
        })),
      },
    },
  ];

  return (
    <>
      <div
        className="filters"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={
            {
              /* Set initial values if any */
            }
          }
          style={{ width: "100%" }}
          className="machinemap-form"
        >
          {/* <FormBuilder meta={companyFormMeta} form={form} /> */}
          <div style={{ display: "flex" }}>
            {companyFormMeta.map((item: any, index: any) => (
              <FormBuilder meta={[item]} form={form} />
            ))}
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ marginRight: "10px" }}
            >
              {id ? "Update Machine Map" : "Add Machine Map"}
            </Button>
            <Button onClick={() => navigate("/machine-map")}>Cancel</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default MachineMapForm;
