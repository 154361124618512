import { Image } from "antd";
import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // Center vertically
        height: "100vh", // Full height of the viewport
      }}
    >
      <Image
        src="/404.png"
        alt="Not Found"
        height={"70%"}
        width={"100%"}
        preview={false}
      />
    </div>
  );
};

export default NotFound;
