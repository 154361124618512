import React, { useEffect, useState } from "react";
import { Tooltip, message, notification } from "antd";
import { useRecoilState } from "recoil";
import { ColumnsType } from "antd/es/table";
import { breadcrumbState } from "../../../lib/stores/breadcrumb";
import getApiEndpoints from "../../../services/endpoints";
import { del, deleteMethod, get, post } from "../../../services/apiService";
import CommonTable from "../../../lib/components/Table/table";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import DiscardChangeModal from "../../../lib/components/modals/discard-change-modal";
import { useNavigate } from "react-router-dom";
import { companyState } from "../../../lib/stores/companyId";
import CopyClipBoard from "../../../lib/components/copyClipBoard/page";
import MapIcon from "../../../lib/components/MapLocator/page";

interface DataType {
  title: string;
  width: number;
  dataIndex: string;
  key: string;
  sorter: boolean;
}

// Function to check if a string is an ISO 8601 date
const isISO8601Date = (value: any) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const Menus: React.FC = () => {
  const navigate = useNavigate();
  const [, setBreadCrumb] = useRecoilState(breadcrumbState);
  const [companyId] = useRecoilState(companyState);
  const [rowData, setRowData] = useState<any>();
  const user: any =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const userData: any = JSON.parse(user);
  const userRole = userData?.roles[0];
  const apiEndpoints = getApiEndpoints(userRole);
  const initialValues = { Machine: "all", Location: "all" };

  const renderStatus = (value?: any, key?: any, record?: any) => {
    if (key === "updatedAt") {
      if (value !== null && isISO8601Date(value)) {
        const date = new Date(value);
        return (
          <div
            onClick={() => navigate(`/menu-item-price/${record?.menuId}`)}
            className="menu-record"
          >
            {date.toLocaleDateString()}
          </div>
        );
      }
    }

    // Handling other truthy values with ellipsis and tooltip
    if (value) {
      return (
        <Tooltip title={value}>
          <div
            className="menu-record"
            onClick={() => navigate(`/menu-item-price/${record?.menuId}`)}
          >
            {value}
          </div>
        </Tooltip>
      );
    }

    // Default case for falsy values other than boolean false
    return "NA";
  };
  useEffect(() => {
    setBreadCrumb([
      { label: "Masters", href: "/menu" },
      { label: "Menu", href: "/menu" },
    ]);
  }, [setBreadCrumb]);

  const onSubmit = async () => {
    try {
      const response = await get(apiEndpoints.menus);
      if (response.status === 200) {
        setRowData(response?.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error while fetching");
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  const handleEdit = (record: any) => {
    navigate(`/menu/edit/${record.menuId}`);
  };

  const handleDeleteConfirmation = async (itemId: string) => {
    try {
      if (itemId) {
        const response = await deleteMethod(
          `${apiEndpoints.addMenu}/${itemId}`
        );
        if (response.status === 200) {
          notification.success({
            message: "Successfully Deleted",
          });
          await onSubmit();
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleDelete = (record: any) => {
    DiscardChangeModal(
      "Click OK to Confirm to Delete.",
      async () => {
        handleDeleteConfirmation(record?.menuId);
      },
      () => {},
      { title: "Are you sure you want to Delete ?" }
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Menu ID",
      dataIndex: "menuId",
      key: "menuId",
      sorter: (a: any, b: any) =>
        (a?.menuId?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.menuId?.toString() ?? "").toLowerCase()),
      width: 50,
      // render: renderStatus,
      render: (value, record) => renderStatus(value, "menuId", record),
    },
    {
      title: "Menu  Name",
      dataIndex: "menuName",
      key: "menuName",
      sorter: (a: any, b: any) =>
        (a?.menuName?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.menuName?.toString() ?? "").toLowerCase()),
      width: 50,
      render: (value, record) => renderStatus(value, "menuName", record),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: any, b: any) =>
        (a?.updatedAt?.toString() ?? "")
          .toLowerCase()
          .localeCompare((b?.updatedAt?.toString() ?? "").toLowerCase()),
      width: 50,
      render: (value, record) => renderStatus(value, "updatedAt", record),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 30,
      render: (record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(record)} />
          </Tooltip>{" "}
          &nbsp; &nbsp;&nbsp;
          <Tooltip title="Delete">
            <DeleteOutlined onClick={() => handleDelete(record)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <CommonTable
        routePath="/menu/add"
        showDates={false}
        showDropDowns={false}
        pageName="Mobile-Devices"
        dataSource={rowData}
        columns={columns}
        initialValues={initialValues}
        onFinish={onSubmit}
      />
    </>
  );
};

export default Menus;
